<template>
  <span
    :id="componentId"
    :class="{ 'letter-avatar': true, 'letter-avatar-square': square }"
    data-testid="letter-avatar"
    :style="{ width: size, height: size }"
  >{{ letter }}</span>
</template>

<script setup lang="ts">
import { KUI_ICON_SIZE_50 } from '@kong/design-tokens'

const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  square: {
    type: Boolean,
    default: false,
  },
  size: {
    type: String,
    default: KUI_ICON_SIZE_50,
  },
  /** Provide custom CSS rules, scoped to this component */
  styles: {
    type: String,
    required: false,
    default: '',
  },
})

// Inject any custom `props.styles` scoped by the `componentId` into the document head
const { componentId } = useCustomStyles(computed(() => props.styles), useAttrs().id as string)

const letter = computed((): string => props.name?.slice(0,1)?.toUpperCase() || '')
const background = computed(() => AVATAR_COLOR_MAP.get(letter.value) || undefined)
</script>

<style scoped lang="scss">
.letter-avatar {
  align-items: center;
  background-color: v-bind(background);
  border-radius: var(--kui-border-radius-circle, $kui-border-radius-circle);
  color: var(--kui-color-text, $kui-color-text);
  display: inline-flex;
  font-family: $kui-font-family-text;
  font-size: var(--kui-font-size-40, $kui-font-size-40);
  font-weight: var(--kui-font-weight-semibold, $kui-font-weight-semibold);
  justify-content: center;
  line-height: var(--kui-line-height-40, $kui-line-height-40);

  &-square {
    border-radius: var(--kui-border-radius-10, $kui-border-radius-10);
  }
}
</style>
