export const AVATAR_COLOR_MAP = new Map()
  .set('A', '#BEE2FF')
  .set('B', '#8FC1FF')
  .set('C', '#5F9AFF')
  .set('D', '#B3F1FF')
  .set('E', '#00C8F4')
  .set('F', '#CFC8FF')
  .set('G', '#9878FF')
  .set('H', '#B5FFEE')
  .set('I', '#00D6A4')
  .set('J', '#FFF296')
  .set('K', '#FFC400')
  .set('L', '#FFABAB')
  .set('M', '#FF3954')
  .set('N', '#FFB4D8')
  .set('O', '#FF3C99')
  .set('P', '#E0E4EA')
  .set('Q', '#AFB7C5')
  .set('R', '#BEE2FF')
  .set('S', '#5F9AFF')
  .set('T', '#B3F1FF')
  .set('U', '#00C8F4')
  .set('V', '#CFC8FF')
  .set('W', '#9878FF')
  .set('X', '#B5FFEE')
  .set('Y', '#00D6A4')
  .set('Z', '#00D6A4')
